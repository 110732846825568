import * as React from 'react'
import { Flex } from 'grid-styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet'
import Hero from '../../components/Hero'
import Button from '../../components/Button'
import BackgroundSection from '../../components/BackgroundSection'
import MainLayout from '../../components/Layout'
import { AboutContact } from '..'
import {
  BeforeAfter,
  StyledTabs,
  StyledTabList,
  StyledTab,
  Content,
} from '../../components/procedures'
import { TabPanel } from 'react-tabs'

const content = {
  backgroundTitle: 'Other Breast Options',
  subtitle: `Trusted, Experienced & Ethical. Boutique plastic surgery on Sydney's North Shore.`,
  title: 'Breast Reconstruction',
  otherOptions: [
    {
      text: 'Breast Augmentation',
      to: '/procedures/breast-augmentation',
    },
    {
      text: 'Breast Reconstruction',
      to: '/procedures/breast-reconstruction',
    },
    {
      text: 'Breast Reduction',
      to: '/procedures/breast-reduction',
    },
  ],
}

export default ({ data }) => (
  <MainLayout header="light">
    <Helmet>
      <title>Breast Reconstruction in Sydney - Dr. Graham Sellars</title>
      <meta
        name="description"
        content="Dr. Graham Sellars performs breast reconstruction surgeries in Sydney, NSW. Improve your figure, silhouette, and posture with this cosmetic procedure."
      />
    </Helmet>

    <Hero bgImage={data.womanWaterfall.childImageSharp} height="600px">
      <h1>{content.title}</h1>
      <p>{content.subtitle}</p>
      <Flex>
        <Button color="#fff" small={true} outline={true}>
          Learn more
        </Button>
      </Flex>
    </Hero>

    <Content>
      <h2>Breast Reconstruction</h2>

      <p>
        Breast reconstruction is a personal and understandably emotional issue
        for women with breast cancer. There are many options. They may be
        confusing and often difficult to decipher. This is a guide to some of
        the issues in breast reconstruction. It is by no means an exhaustive
        list of all the benefits and complications of various procedures. Not
        all options are available to every woman.
      </p>
      <h4>
        More individual/specific advice can only be determined in private
        consultation with Dr Sellars.
      </h4>
      <p>Breast reconstruction usually requires more than one operation.</p>

      <Link to="/contact/">
        <Button inverted={true}>Contact us today!</Button>
      </Link>
    </Content>

    <BackgroundSection
      image={data.otherBreast.childImageSharp}
      title={content.backgroundTitle}
      height="500px"
      darkness="0.4"
    >
      <p style={{ fontSize: 18, margin: '-1rem 0 2rem' }}>
        Browse Dr. Graham Sellars' full range of surgical and non-surgical
        procedures.
      </p>
      {content.otherOptions.map(({ to, text }) => (
        <Flex mr={2} mb={3} style={{ float: 'left' }}>
          <Link to={to}>
            <Button small={true} color="#fff" outline={true}>
              {text}
            </Button>
          </Link>
        </Flex>
      ))}
    </BackgroundSection>

    <StyledTabs style={{ marginTop: 50 }}>
      <h2>Information about {content.title}</h2>
      <StyledTabList>
        <StyledTab>Surgical Options</StyledTab>
        <StyledTab>Skin-sparing Mastectomy </StyledTab>
        <StyledTab>Nipple and Areolar Reconstruction</StyledTab>
        <StyledTab>Risks of Surgery</StyledTab>
      </StyledTabList>

      <TabPanel>
        <h4>Timing of Breast Reconstruction</h4>
        <p>
          Breast reconstruction can be done immediately following mastectomy, or
          some time later, after the area has healed. The best time will vary
          from patient to patient and may be influenced by other possible
          treatments such as radiotherapy and chemotherapy. The procedure that
          best suits you will depend on your age, general health, size and shape
          of the other breast and available body tissue.{' '}
        </p>
        <h4>Common Surgical Options </h4>
        <ul>
          <li>Reconstruction with immediate implant insertion</li>
          <li>Reconstruction using tissue expanders, followed by implants</li>
          <li>
            Reconstruction using a combination of your own tissue and implants
          </li>
          <li>Reconstruction using your own tissue</li>
          <li>Nipple/Areolar Reconstruction</li>
          <li>Additional surgery to the “normal” unaffected breast</li>
        </ul>

        <p>
          Dr Sellars does not perform any tissue transfer procedures for post
          mastectomy such as TRAM flaps. Dr Sellars can offer patients implant
          reconstruction.{' '}
        </p>

        <p>
          If you are interested in a tissue transfer procedure such as a TRAM
          Flap, Dr Sellars is able to recommend other Plastic Surgeons to
          perform this for you.
        </p>
        <p>
          Reconstruction immediately after mastectomy generally does not
          interfere with chemotherapy or radiotherapy. However some studies have
          suggested that implants may not be a good option for women who need
          radiotherapy because radiotherapy can cause the skin around the
          implant to tighten or shrink.
        </p>
        <p>
          Complications of surgery and compromise of the end result increases
          where radiotherapy is used. This of course is balanced by the
          therapeutic benefit of radiotherapy.
        </p>
        <p>
          All options carry the general risks of surgery and anaesthesia. All
          options have the potential problems of wound healing which vary with
          individual circumstances.
        </p>
      </TabPanel>
      <TabPanel>
        <h4>Skin-sparing Mastectomy - Breast Implant after Tissue Expansion</h4>
        <p>
          Often a mastectomy (removal of breast tissue) can be performed by
          removing the breast tissue while preserving the overlying skin. This
          allows an opportunity to then insert an implant to replace the lost
          breast tissue.{' '}
        </p>
        <p>
          Occasionally an implant can be inserted immediately after mastectomy
          in the same operation, if only a small implant is required, however
          more commonly a two-staged approach is required using a tissue
          expander. Read below.{' '}
        </p>
        <p>
          Usually, before an implant can be put in, a tissue expander needs to
          be in place for a period of a few weeks.
        </p>
        <h4>What is a tissue expander?</h4>
        <p>
          A tissue expander is a small balloon-like device (a little like a
          deflated breast implant). It is surgically inserted under the skin
          usually at the time of mastectomy surgery. Over several weeks or
          months, a saline solution is injected once or twice a week into the
          expander through a tiny valve under the skin.{' '}
        </p>

        <p>
          This is done in the consulting rooms under local anaesthetic. As the
          solution inflates the expander, the overlying tissue is gradually
          stretched, making room for the permanent breast implant.
        </p>

        <h4>Why not put the breast implant in straight away?</h4>

        <p>
          The reason for stretching of the skin with an expander first, is that
          the new mastectomy scar needs to be able to heal without too much
          initial pressure on it. An implant may cause the scar to stretch or
          even break down if inserted too soon. The expander is a way of slowly
          increasing the space without putting pressure on the new scar.
        </p>
        <h4>When does the breast implant get put in?</h4>
        <p>
          When the patient and surgeon are happy with the final size after
          expansion, the second stage procedure is to then remove the tissue
          expander and replace it with the permanent breast implant. This
          procedure is carried out under general anaesthetic which means another
          brief hospital admission and operation.
        </p>
        <p>
          The implant size and shape is matched as closely to the other
          unaffected breast. Asymmetry is a common issue in breast
          reconstruction surgery and occasionally patients may want a breast
          reduction or lift of the unaffected breast, to create an even more
          aesthetic pair. Occasionally patients may decide to change the size
          and shape of the implant at a later date.
        </p>
        <p>
          While surgeons will do their best to recreate a natural looking
          breast, patients must remember that this is a post cancer
          reconstruction and there are multiple issues to address.{' '}
        </p>

        <p>
          Dr Sellars performs breast reconstruction procedures in conjunction
          with a breast oncology specialist at The Sydney Adventist Hospital.
          The oncology doctor performs the mastectomy and Dr Sellars does the
          reconstruction.
        </p>
      </TabPanel>
      <TabPanel>
        <h4>Nipple and Areolar Reconstruction</h4>
        <p>
          This operation can be performed some months after the breast
          reconstruction. The reason for the delay is that the new breast will,
          at first, be higher than the other side. If the nipple were
          reconstructed too soon, it would not be positioned correctly.
        </p>
        <p>
          The nipple can be reconstructed using part of the other nipple, a
          local flap or skin from elsewhere. At a later date the colour of the
          nipple and areolar can be added, using a special tattoo method.
        </p>
      </TabPanel>
      <TabPanel>
        <h4>Possible risks of any surgery include:</h4>
        <ul>
          <li>Wound infection</li>
          <li>Pain and discomfort</li>
          <li>Nausea</li>
          <li>Haematoma (an accumulation of blood around the surgical site that may require drainage)</li>
          <li>Heavy bleeding from the incisions</li>
          <li>Keloid or hypertrophic scars</li>
          <li>Slow healing</li>
          <li>Separation of the wound edges</li>
          <li>Allergies to anaesthetic agents, antiseptics, suture material or dressings</li>
          <li>Deep vein thrombosis DVT (blood clot in the leg)</li>
        </ul>

        <h4>Specific breast reconstruction risks:</h4>
        <ul>
          <li>Haematoma or seroma (collection of blood or fluid) that may require draining</li>
          <li>Infection around the implant</li>
          <li>Capsular contracture</li>
          <li>Leakage of the implant’s contents (silicone or saline)</li>
          <li>Difference in size and shape (asymmetry) between the natural and reconstructed breasts</li>
        </ul>
      </TabPanel>
    </StyledTabs>

    <AboutContact data={data} />
  </MainLayout>
)
export const query = graphql`
  query BreastReconstructionQuery {
    allFile(filter: { relativeDirectory: { eq: "breast-reduction" } }) {
      edges {
        node {
          childImageSharp {
            resolutions(width: 500, height: 300, toFormat: JPG) {
              ...GatsbyImageSharpResolutions
            }
          }
        }
      }
    }
    womanWaterfall: file(relativePath: { eq: "background-generic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    otherBreast: file(relativePath: { eq: "woods-woman.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asaps: file(relativePath: { eq: "ASAPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asps: file(relativePath: { eq: "ASPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    drSellars: file(relativePath: { eq: "dr-graham-sellars.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fracs: file(relativePath: { eq: "FRACS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
